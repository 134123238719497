var solink = {};

(function (window, $) { // code isolation

    var loading_html = '<div class="progress loading-progress  light-blue lighten-5"><div class="indeterminate primary-bg"></div></div>';

    var datepicker_options = {
        container: $('body'),
        format: 'dd/mm/yyyy',
        firstDay: 1,
        onOpen: function () {
            // debug materialize picker
            var m = moment(this.el.value, 'DD/MM/YYYY');
            this.setDate(m.toDate());
        },
        i18n: {
            cancel: 'Fermer',
            clear: 'Réinitialiser',
            done: 'OK',
            months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
            monthsShort: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
            weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
            weekdaysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
            weekdaysAbbrev: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
        }
    };

    var datatable_options = {
        "lengthChange": false,
        "searching": false,
        "order": [],
        "fnDrawCallback": function (oSettings) {
            if (oSettings._iDisplayLength >= oSettings.fnRecordsDisplay()) {
                $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
            }

            solink.refreshWidgets($(oSettings.nTable));
            solink.initAjax();
            // solink.initLifesheet();

        },
        "language": {
            "sProcessing": "Traitement en cours...",
            "sSearch": "Rechercher&nbsp;:",
            "sLengthMenu": "Afficher _MENU_ &eacute;l&eacute;ments",
            "sInfo": "Affichage de l'&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
            "sInfoEmpty": "Affichage de l'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ment",
            "sInfoFiltered": "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
            "sInfoPostFix": "",
            "sLoadingRecords": "Chargement en cours...",
            "sZeroRecords": "Aucun &eacute;l&eacute;ment &agrave; afficher",
            "sEmptyTable": "Aucune donn&eacute;e disponible dans le tableau",
            "oPaginate": {
                "sFirst": "Premier",
                "sPrevious": "Pr&eacute;c&eacute;dent",
                "sNext": "Suivant",
                "sLast": "Dernier"
            },
            "oAria": {
                "sSortAscending": ": activer pour trier la colonne par ordre croissant",
                "sSortDescending": ": activer pour trier la colonne par ordre d&eacute;croissant"
            },
            "select": {
                "rows": {
                    _: "%d lignes séléctionnées",
                    0: "Aucune ligne séléctionnée",
                    1: "1 ligne séléctionnée"
                }
            }
        }
    };

    solink.initWidgets = function (parent) {
        if ('undefined' === typeof parent) {
            parent = $('body');
        }
        // console.log(parent);
        parent.find('.dropdown-trigger').dropdown();
    }

    solink.initAjax = function (parent) {

        if ('undefined' === typeof parent) {
            parent = $('body');
        }

        parent.find('.data-ajax-destination').each(function (k, obj) {
            obj = $(obj);
            var options = {};
            if ('undefined' !== typeof obj.data('ajaxOptions')) {
                options = obj.data('ajaxOptions');
            }

            var trigger = $(options.trigger);

            trigger.click(function (e) {
                e.preventDefault();
                obj.addClass('loading').removeClass('hide').html(loading_html);

                if (options.open) {
                    console.log("options : ", options);
                    console.log("options.open : ", options.open);
                    M.Modal.getInstance($(options.open)).open();
                }
                console.log(trigger[0].href);
                jQuery.getJSON(trigger[0].href, function (data) {


                    obj.html(data.html).removeClass(['loading', 'hide']);
                    if (options.onLoadRemove) {
                        $(options.onLoadRemove).hide();
                    }
                    solink.refreshWidgets(obj);
                });

            })

        });
    };

    solink.refreshWidgets = function (parent) {
        if ('undefined' === typeof parent) {
            parent = $('body');
        }
        // parent.find('.dataTable').DataTable(datatable_options);
        parent.find('.dropdown-trigger').dropdown();
        parent.find('.modal').modal();
        parent.find('select').formSelect({
            dropdownOptions: {
                container: $('body'),
            }
        });
        parent.find('.materialboxed').materialbox();
        parent.find('.datepicker').datepicker(datepicker_options);
        M.updateTextFields();
        if (parent.find('.materialize-textarea').length) {
            M.textareaAutoResize(parent.find('.materialize-textarea'));
        }
    };

    solink.unfoldingDump = function () {
        $("pre.sf-dump").each(function() {
            console.log("Class : " , $(this).parent().attr('class'));
            if(!$(this).parent().hasClass("noExpand")) {
                $(this).find("a.sf-dump-toggle:gt(0)").each(function (i, a) {
                    // $(this).next('.sf-dump-compact').show();
                    a.click();
                });
            }
        });
    };

    solink.paymoCallApi = function () {

        $("#endpoint").change(function () {
            var dataParam = $('option:selected', this).attr('data-param');
            if(dataParam != "") {
                console.log("DataParam: ", dataParam, $("#" + dataParam).closest("*[data-type='optionnal']"));
                $("#" + dataParam).closest("*[data-type='optionnal']").removeClass("hide");
            } else {
                $(this).parents().find("*[data-type=optionnal]").addClass("hide");
            }
        });

        $("#paymoCallApi").on("click", function() {
            var api = $("#endpoint").val();
            var dataParam = $('option:selected', $("#endpoint")).attr('data-param');
            var datas = "";
            console.log(dataParam, datas)
            if(dataParam !== "") {
                datas = dataParam + "=" + $("#" + dataParam).val();
            }

            console.log(dataParam, datas)

            if (api != "") {
                $.ajax({
                    url: "/admin/paymo/ajax/" + api,
                    method: "GET",
                    data: datas,
                    success: function (response) {
                        $("#paymoResponse").html(response.html);
                        // console.log(response);
                    }
                });
            }
        });
    };

    solink.cardHeight = function () {
        var height = 0;
        $('.cardEmployee').each(function() {
            if($(this).find('.s9').height() > height) {
                console.log($(this).find('.s9').height() , height);
                height = $(this).find('.s9').height();
            }
        });
        $('.cardEmployee').each(function() {
            $(this).height(height + "px");
        });
    };

    solink.trOvhIp = function () {
        // console.log("tr : ", $(".trIpOvh").length);
        $(".trIpOvh").each(function() {
            var tr = this;
            var ip = $(this).attr("data-ip");
            var ip2 = ip.substr(0, ip.indexOf('/'));
            var ipEncoded = ip;
            ipEncoded.replace("\/","%2F");

            if(ip.indexOf("\/64") == -1) {
                $.ajax({
                    url: "/admin/ovh/get/?method=/ip/" + ip2,
                    dataType: "json",
                    success: function (response) {
                        if (response && response.routedTo && response.routedTo.serviceName && response.type) {
                            $(tr).find(".type").html(response.routedTo.serviceName + "<br /><small><i>(" + response.type + ")</i></small> ");

                            if(response.type == "cloud") {
                                $.ajax({
                                    url: "/admin/ovh/get/?method=/cloud/project/" + response.routedTo.serviceName,
                                    dataType: "json",
                                    success: function (responseDedicated) {
                                        if (responseDedicated.reverse)
                                            $(tr).find(".reverse").html(responseDedicated.reverse);
                                    }
                                });
                            } else if(response.type == "vps") {
                                $.ajax({
                                    url: "/admin/ovh/get/?method=/vps/" + response.routedTo.serviceName,
                                    dataType: "json",
                                    success: function (responseDedicated) {
                                        if(responseDedicated.displayName)
                                            $(tr).find(".reverse").html(responseDedicated.displayName);
                                    }
                                });
                            } else {
                                $.ajax({
                                    url: "/admin/ovh/get/?method=/dedicated/server/" + response.routedTo.serviceName,
                                    dataType: "json",
                                    success: function (responseDedicated) {
                                        if(responseDedicated.reverse)
                                            $(tr).find(".reverse").html(responseDedicated.reverse);
                                    }
                                });
                            }
                        }
                    },
                    error: function (response) {
                        console.log("ERROR");
                    }
                });
            } else {
                $.ajax({
                    url: "/admin/ovh/get/",
                    data: "method=/ip/" + ipEncoded + "/delegation",
                    dataType: "json",
                    success : function(response){
                        $(tr).find(".delegate").html(response);
                    },
                    error : function(response){
                        console.log("ERROR");
                    }
                });
            }
        });
    };

    solink.uptimerobot = function () {
        $("div.overflowScroll").height($(window).height()-220);
        window.addEventListener('resize', function () {
            $("div.overflowScroll").height($(window).height()-220);
        });

        $( ".droppable" ).droppable({
            classes: {
                "ui-droppable-active": "ui-state-highlight"
            },
            drop: function( event, ui ) {
                var droppable = $(this);
                var draggable = ui.draggable.clone();

                var monitorId = $(draggable).attr("data-id");
                var groupId = $(droppable).attr("data-id");

                var addMonitor = true;

                $(droppable).find(".embedMonitor").each(function() {
                    if($(this).attr("data-id") == monitorId && $(this).attr("data-group-id") == groupId) {
                        addMonitor = false;
                    }
                });

                if(addMonitor) {
                    draggable.find(".removeMonitor").removeClass("hide");
                    $(droppable).find(".clearfix").before(draggable);
                    $.ajax({
                        url: "/admin/uptimerobot/ajax",
                        method: "POST",
                        data: "action=addmonitorToGroup&monitorId=" + monitorId + "&groupId=" + groupId,
                        success : function(response){
                            $(draggable).find(".embedMonitor").attr("data-group-id", groupId);
                        },
                        error : function(){
                            alert("Erreur ajax à l'ajout du moniteur !" + response);
                        }
                    });
                } else {
                    alert("Monitor déjà présent !");
                }
            }
        });

        $( ".draggable" ).draggable({
            revert: "invalid",
            helper: "clone",
            cursor: "move"
        });

        $('#addGroup').on("click", function() {
            $.ajax({
                url: "/admin/uptimerobot/ajax",
                method: "POST",
                data: "action=addGroup&label=" + $("#group_label").val(),
                success : function(response){
                    document.location.reload(true);
                },
                error : function(response){
                    console.log("ERROR");
                }
            });
        });
        $('.removeGroup').on("click", function() {
            var id = $(this).attr("data-id");
            $.ajax({
                url: "/admin/uptimerobot/ajax",
                method: "POST",
                data: "action=deleteGroup&id=" + id,
                success : function(response){
                    document.location.reload(true);
                },
                error : function(response){
                    console.log("ERROR");
                }
            });
        });

        $(document).on("click", ".removeMonitor", function () {
            if(confirm('Etes-vous sûr de vouloir supprimer ce monitor ?')) {
                var button = this;
                $.ajax({
                    url: "/admin/uptimerobot/ajax",
                    method: "POST",
                    data: "action=removeMonitor&monitorId=" + $(button).attr("data-id") + "&groupId=" + $(button).parent().parent().parent().attr("data-group-id"),
                    success : function(response){
                        $(button).parent().parent().parent().remove();
                    },
                    error : function(){
                        alert("Erreur ajax à la suppression du moniteur !");
                        console.log("Erreur ajax à la suppression du moniteur !" + response.toString());
                    }
                });
            }
        });
    };

    solink.dashboard = function () {
        function checkTime(i) {
            if (i < 10) {
                i = "0" + i;
            }
            return i;
        }

        function startTime() {
            var today = new Date();
            var h = today.getHours();
            var m = today.getMinutes();
            var s = today.getSeconds();
            // add a zero in front of numbers<10
            m = checkTime(m);
            s = checkTime(s);
            if (document.getElementById('timeMonitors') !== null) {
                document.getElementById('timeMonitors').innerHTML = h + ":" + m + ":" + s;
            }
            t = setTimeout(function() {
                startTime()
            }, 500);
        }
        startTime();

        $("#refreshMonitors").on("click", function() {
            getMonitors();
        });
    };
})(window, jQuery); // code isolation end

// ============================
// LETS GO

$(document).ready(function () {
    solink.initWidgets();
    solink.refreshWidgets();
    solink.initAjax();
    solink.unfoldingDump();
    solink.cardHeight();
    solink.trOvhIp();
    solink.paymoCallApi();
    solink.uptimerobot();
    solink.dashboard();
});

var online = 0, offline = 0, paused = 0, question = 0;

getMonitors = function() {
    $.ajax({
        url: "/uptimerobot/ajax/" + $(".bodyDashboard").attr('data-key'),
        method: "POST",
        success : function(response){
            $(".dashboardMonitorsContainer").html(response);

            online = 0;
            offline = 0;
            paused = 0;
            question = 0;

            $(".dashboardMonitorsContainer .embedMonitor > div").each(function() {
                if($(this).hasClass("green")) online = parseInt(online)+1;
                if($(this).hasClass("red")) offline = parseInt(offline)+1;
                if($(this).hasClass("grey")) paused = parseInt(paused)+1;
                if($(this).hasClass("orange")) question = parseInt(question)+1;
                if($(this).hasClass("red"))
                    console.log($(this).html());
            });
            $("#countOnline").html(online);
            $("#countOffline").html(offline);
            $("#countPaused").html(paused);
            $("#countQuestion").html(question);

            if(offline == 0) {
                // console.log("COME BACK ONLINE");
                if($(".bg-img").hasClass("bgDatacenterAlert")) {
                    var audioAlert = new Audio('/mp3/yahoo.mp3');
                    audioAlert.play();
                    $("#fire").hide();
                }
                $(".bg-img").removeClass("bgDatacenterAlert").addClass("bgDatacenter");
            } else {
                // console.log(" OFF LINE !!! ");
                var audioAlert = new Audio('/mp3/scream.mp3');
                audioAlert.play();
                $(".bg-img").removeClass("bgDatacenter").addClass("bgDatacenterAlert");
                $("#fire").show();
            }
        },
        error : function(){
        }
    });
}
